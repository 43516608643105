import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Head from '../components/Head'
import { Disqus } from 'gatsby-plugin-disqus'
import blogStyles from '../styles/components/blog.module.scss'

export const query = graphql`
  query (
    $slug: String!
  ){
    markdownRemark  (
      frontmatter: {
        slug: {
          eq: $slug
        }
      }
    ){
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        slug
        tags
      }
      html
      timeToRead
    }
  }
`

const Blog = ({ data, location }) => {
  let { title, slug, date, tags } = data.markdownRemark.frontmatter
  let { timeToRead } = data.markdownRemark
  tags = tags || []
  let disqusConfig = {
    url: `http://derricksdocs.com${location.pathname}`,
    identifier: slug,
    title
  }
  return (
    <Layout>
      <div className="page-section container">
        <Head title={title} />
        <section className={`${blogStyles.blogPost} content`}>
          <h1 className="title is-1 has-text-soft-red">{title}</h1>
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <span>{date}</span>
                <span>({timeToRead} minute read)</span>
              </div>
              <div className="level-item">
                {tags.map((tag) => {
                  return (
                    <span class="blog-tag tag is-primary">{tag}</span>
                  )
                })}
              </div>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html}}></div>
        </section>
      </div>
      <section className="hero has-background-info">
        <div className="page-section container">
          <Disqus config={disqusConfig} />
        </div>
      </section>
    </Layout>
  )
}

export default Blog
